import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCalendarExceptionAPI } from 'api/controllers/CalendarExceptionAPI';
import { ICalendarException } from 'api/models/CalendarException';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { Headline2, Headline3 } from 'styles/FontStyles';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { DateInput } from 'components/common/inputs/DateInput';
import { Dropdown } from 'components/common/inputs/Dropdown';
import { Form } from 'components/common/inputs/Form';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

export const CalendarExceptionForm: FC<{
  customerCategories: ICustomerCategory[];
  calendarException: ICalendarException;
}> = (props) => {
  const { customerCategories = [], calendarException } = props;

  const navigate = useNavigate();

  const calendarExceptionAPI = useCalendarExceptionAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const customerCategoryOptions = [
    { value: '', label: 'Alle' },
    ...customerCategories.map((customerCategory) => ({
      value: customerCategory.id.toString(),
      label: customerCategory.name,
    })),
  ];
  const form = useForm({
    defaultValues: {
      name: calendarException.name,
      begin_date: calendarException.beginDate,
      end_date: calendarException.endDate,
      public_holiday: calendarException.publicHoliday,
      customer_category_id: calendarException.customerCategoryId,
    },
  });

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          setSubmitting(true);
          const apiCall = calendarException.id
            ? calendarExceptionAPI.updateCalendarException(calendarException.id, { calendar_exception: values })
            : calendarExceptionAPI.createCalendarException({ calendar_exception: values });
          apiCall
            .then((res) => {
              navigate('/calendar-exceptions');
            })
            .catch((err) => {
              // TODO add generic error message
            })
            .finally(() => setSubmitting(false));
        }}
      >
        <Title>Schließzeiten</Title>

        <Section>
          <SectionTitle>Allgemeine Informationen</SectionTitle>
          <GridRow>
            <TextInput
              id={'name'}
              placeholder={'Sommerferien 2024'}
              label={'Name'}
              $plain
              {...form.register('name', { required: 'Bitte wählen Sie einen Namen an.' })}
            />
          </GridRow>
          <GridRow>
            <DateInput label={'Startdatum'} {...form.register('begin_date', { required: 'Bitte wählen Sie einen Startdatum an.' })} />
            <DateInput label={'Enddatum'} {...form.register('end_date', { required: 'Bitte wählen Sie einen Enddatum an.' })} />
          </GridRow>
          <GridRow>
            <Dropdown
              id="customer_category_id"
              name="customer_category_id"
              label="Kundenkategorie"
              items={customerCategoryOptions}
              defaultValue={calendarException.customerCategoryId}
              optional={true}
            />
          </GridRow>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
};
