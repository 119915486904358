import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';

export const useScheduledTourException = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const deleteScheduledTourException = async (params: { id: number }) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/scheduled-tour-exceptions/${params.id}`, { ...authHeader });
    });
  };

  const updateScheduledTourException = async (params: { id: number; includeTourOnInvoice: boolean }) => {
    await checkAuth();
    return await axios.patch(
      `/api/scheduled-tour-exceptions/${params.id}`,
      { scheduled_tour_exception: { 'include_on_invoice?': params.includeTourOnInvoice } },
      { ...authHeader },
    );
  };

  return {
    deleteScheduledTourException,
    updateScheduledTourException,
  };
};
