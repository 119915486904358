import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSchedulesAPI } from 'api/controllers/SchedulesAPI';
import { IScheduledTour, IScheduledTourException } from 'api/models/ScheduledTour';
import { useShowDialog } from 'state/DialogState';
import { Colors } from 'styles/Colors';
import { Body3 } from 'styles/FontStyles';
import { getCurrentDayDate, getFormattedValue, getISODate } from 'utils/dateUtils';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { Breadcrumb } from '../Breadcrumb';

import { useLatestBookingCompletionDate } from 'state/LatestBookingCompletionState';
import { ITourConflictsResult } from 'state/TourConflictsState';
import { BREAKPOINT_XL } from 'styles/Breakpoints';
import { formatAddress } from 'utils/addressUtils';
import { getMapsUrl } from 'utils/mapsUtils';
import { IScheduleWithMetaDataDetail } from 'components/content/tours/TourDetails';
import { Map } from 'components/icons/Map';
import { Tours } from 'components/icons/Tours';
import { HighlightedLink } from 'components/common/atoms/Link';
import BookingCompletionHint from '../BookingCompletionHint';
import ChangeTourDialogOverlay from './ChangeTourDialogOverlay';
import PDFDownloadButton from './PDFDownloadButton';
import CancelTourDialog from './tour-details/CancelTourDialog';
import ConflictBanner from './tour-details/ConflictBanner';
import DateInput from './tour-details/DateInput';
import DateTile from './tour-details/DateTile';
import HeaderButtons from './tour-details/HeaderButtons';
import ScheduledExceptions from './tour-details/ScheduledExceptions';
import TourTile from './tour-details/TourTile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  ${BREAKPOINT_XL} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
  }
`;

const TilesLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 2rem;

  margin-block-start: 1rem;

  ${BREAKPOINT_XL} {
    grid-template-columns: 1.5fr 1fr;
  }
`;

const TilesLayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const TilesLayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const TilesLayoutRowSwitchEnd = styled(TilesLayoutRow)`
  justify-content: flex-end;
  align-self: center;
  grid-row: 1;

  ${BREAKPOINT_XL} {
    grid-row: auto;
  }
`;

const TilesLayoutButtonWrapper = styled(TilesLayoutRow)`
  justify-content: flex-end;
  align-self: center;
`;

const InfoTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding-block: 1rem;
  padding-inline: 1.5rem;

  ${Body3};

  background-color: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

export interface ITourDetailsViewProps {
  scheduledTourWithMetaData: IScheduleWithMetaDataDetail;
  timestamp: string;
  forceOriginalTour: boolean;
  setForceOriginalTour: (forceOriginalTour: boolean) => void;
  onExceptionClick: (exception: IScheduledTourException | undefined) => void;
  setTimestamp: (timestamp: string) => void;
  onUpdate?: (newDate: string) => void;
  selectedException?: IScheduledTourException;
  activeException?: IScheduledTourException;
  exceptionsForDate: IScheduledTourException[];
}
function TourDetailsView(props: ITourDetailsViewProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const schedulesAPI = useSchedulesAPI();
  const showDialog = useShowDialog();

  const {
    timestamp,
    forceOriginalTour,
    scheduledTourWithMetaData,
    selectedException,
    activeException,
    setTimestamp,
    onExceptionClick,
    setForceOriginalTour,
    exceptionsForDate,
  } = props;

  const bookingCompletionDate = useLatestBookingCompletionDate(false);

  const { tour, isTourDriven, exceptionTour, activeDay } = scheduledTourWithMetaData;
  const initialTour = exceptionTour !== undefined && exceptionTour && !forceOriginalTour ? exceptionTour : tour;

  const [scheduledTour, setScheduledTour] = useState<IScheduledTour>(initialTour || tour);
  const [scheduleConflicts, setScheduleConflicts] = useState<ITourConflictsResult>();
  const [showChangeDurationDialog, setShowChangeDurationDialog] = useState(false);

  // props from original scheduled-tour

  const {
    arrivalDate,
    customerId,
    daysOfWeek,
    departureDate,
    direction,
    endDate,
    id,
    isException,
    name,
    passengers = [],
    startDate,
  } = scheduledTour;

  // Refactor: TO be delivered from backend
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('date', getISODate(getCurrentDayDate(timestamp || undefined)));
    navigate({ search: searchParams.toString() }, { replace: true });

    const startDate = new Date(timestamp);
    const endDate = new Date(timestamp);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    // get conflicts
    const loadConflictsForSchedule = async () => {
      await schedulesAPI
        .getConflicts({ startDate: startDate.toISOString(), endDate: endDate.toISOString(), customerId: tour.customerId })
        .then((res) => {
          setScheduleConflicts(res.data);
        })
        .catch(() => {});
    };

    // async - no loading animation while fetching conflicts
    loadConflictsForSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  useEffect(() => {
    if (forceOriginalTour) {
      setScheduledTour(tour);
    } else if (exceptionTour !== undefined && exceptionTour) {
      setScheduledTour(exceptionTour);
    }
  }, [setScheduledTour, exceptionTour, forceOriginalTour, tour]);

  const startTime = ((direction === 'return' ? departureDate : passengers[0]?.ScheduledTourPassenger?.pickupDate) || {})[
    new Date(timestamp).getDay()
  ];
  const endTime = ((direction === 'return' ? passengers[passengers.length - 1]?.ScheduledTourPassenger?.pickupDate : arrivalDate) || {})[
    new Date(timestamp).getDay()
  ];

  const [showDatePickerDialog, setShowDatePickerDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [changes, setChanges] = useState<Partial<IScheduledTour> | null>(null);

  useEffect(() => {
    if (changes !== null) {
      setScheduledTour({ ...scheduledTour, ...changes });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  // props from current schedule

  const editable = useMemo(() => {
    if (activeException !== undefined) {
      return activeException.replacementScheduledTourId === scheduledTour.id;
    }
    return true;
  }, [activeException, scheduledTour]);

  // filter conflicts for schedule
  const conflicts = useMemo(() => {
    const todayConflicts = scheduleConflicts?.[timestamp] || [];
    const conflictsForSchedule = todayConflicts.filter((conflict) => conflict.tour.id === id);

    return conflictsForSchedule || [];
  }, [scheduleConflicts, timestamp, id]);

  const proceedWithoutSaving = useCallback(
    (onProceed: () => void) => {
      if (changes !== null) {
        showDialog({
          headline: 'Änderungen gehen verloren',
          body: 'Möchten Sie fortfahren ohne die Änderungen zu speichern?',
          buttons: (
            <>
              <ButtonSecondary onClick={() => showDialog(null)}>Abbrechen</ButtonSecondary>
              <ButtonPrimary
                onClick={() => {
                  setChanges(null);
                  showDialog(null);
                  onProceed();
                }}
              >
                Fortfahren ohne Speichern
              </ButtonPrimary>
            </>
          ),
        });
      } else {
        onProceed();
      }
    },
    [showDialog, changes, setChanges],
  );

  const isBlockedByBookingCompletion = useMemo(
    () => !timestamp || (bookingCompletionDate !== null && bookingCompletionDate >= getCurrentDayDate(timestamp)),
    [timestamp, bookingCompletionDate],
  );

  const googleMapsLink = useMemo(() => {
    const addresses = [
      scheduledTour.direction === 'return' && formatAddress(scheduledTour.customer?.address),
      ...(scheduledTour.passengers || []).map(({ addresses = [] }) => formatAddress(addresses[0])),
      scheduledTour.direction === 'outwards' && formatAddress(scheduledTour.customer?.address),
    ].filter(Boolean) as string[];

    if (addresses.length > 0) {
      return (
        <InfoTile>
          <HighlightedLink style={{ alignSelf: 'flex-start' }} href={getMapsUrl(addresses)}>
            <Map /> In Google Maps anzeigen
          </HighlightedLink>
        </InfoTile>
      );
    }
    return <></>;
  }, [scheduledTour]);

  return (
    <Wrapper>
      <HeaderRow>
        <Breadcrumb
          items={[
            {
              title: 'Fahrten Übersicht',
              href: '/tours',
            },
          ]}
        />

        <HeaderButtons
          setShowCancelDialog={setShowCancelDialog}
          proceedWithoutSaving={proceedWithoutSaving}
          isActiveTour={isTourDriven && (!activeException || scheduledTour.id === activeException.replacementScheduledTourId)}
          isTourDriven={isTourDriven && (!activeException || scheduledTour.id === activeException.replacementScheduledTourId)}
          isBlockedByBookingCompletion={isBlockedByBookingCompletion}
          customerId={customerId}
          direction={direction || 'outwards'}
          id={id || ''}
          timestamp={timestamp || ''}
        />
      </HeaderRow>

      <DateInput
        timestamp={timestamp}
        proceedWithoutSaving={proceedWithoutSaving}
        setTimestamp={setTimestamp}
        id={id || ''}
        scheduleConflicts={scheduleConflicts}
        tour={tour}
        daysOfWeek={daysOfWeek}
        startDate={startDate.toString() || ''}
        endDate={endDate?.toString() || ''}
      />

      <TilesLayout>
        {/* head row */}
        <TilesLayoutRow>
          <DateTile
            timestamp={timestamp}
            setTimestamp={setTimestamp}
            proceedWithoutSaving={proceedWithoutSaving}
            showDatePickerDialog={showDatePickerDialog}
            setShowDatePickerDialog={setShowDatePickerDialog}
            isCurrentDayDriven={isTourDriven}
            startTime={startTime || ''}
            endTime={endTime || ''}
            tour={tour}
            scheduleConflicts={scheduleConflicts}
          />

          <PDFDownloadButton
            scheduleId={tour.id} // root schedule id
            scheduleName={tour.name}
            timestamp={timestamp}
          />
        </TilesLayoutRow>
        <TilesLayoutRowSwitchEnd></TilesLayoutRowSwitchEnd>

        {/* sub head row */}
        <TilesLayoutColumn>
          {activeDay && (
            <ConflictBanner
              timestamp={timestamp}
              daysOfWeek={daysOfWeek}
              conflicts={conflicts}
              activeException={activeException}
              selectedException={selectedException}
              isOriginalTour={scheduledTour.id === tour.id}
              isException={isException || false}
              startDate={startDate.toString() || ''}
              endDate={endDate?.toString() || ''}
            />
          )}
          <BookingCompletionHint timestamp={timestamp} />
        </TilesLayoutColumn>
        <TilesLayoutButtonWrapper>
          <ButtonPrimary
            href={`/customers/${customerId}/tourplan?direction=${direction}&date=${timestamp}&tour=${name}`}
            disabled={isBlockedByBookingCompletion}
          >
            <Tours /> Fahrtenplanung
          </ButtonPrimary>
        </TilesLayoutButtonWrapper>

        {/* main content */}
        <TilesLayoutColumn>
          {scheduledTour !== undefined && scheduledTour !== null && (
            <TourTile
              timestamp={timestamp}
              hasChanged={changes !== null}
              setChanges={setChanges}
              onDiscard={() => {
                if (exceptionTour) {
                  setScheduledTour(exceptionTour);
                }
              }}
              activeDay={activeDay}
              conflicts={conflicts}
              exceptions={exceptionsForDate}
              isBlockedByBookingCompletion={isBlockedByBookingCompletion}
              isTourDriven={isTourDriven}
              activeExceptionId={activeException?.id}
              selectedException={selectedException}
              editable={editable}
              tour={scheduledTour}
              setShowChangeDurationDialog={setShowChangeDurationDialog}
              proceedWithoutSaving={proceedWithoutSaving} /* add other missing properties here */
            />
          )}
        </TilesLayoutColumn>
        <TilesLayoutColumn>
          <InfoTile>
            <b>Tour gültigkeit:</b>
            {getFormattedValue([new Date(scheduledTour.startDate), scheduledTour.endDate ? new Date(scheduledTour.endDate) : null])}
          </InfoTile>

          <InfoTile>
            <b>Anmerkungen zum Fahrplan:</b>
            {activeException?.comment || '-'}
            {scheduledTour.comment ? <i>{scheduledTour.comment}</i> : null}
          </InfoTile>

          {googleMapsLink}
          {exceptionsForDate.length > 0 && (
            <InfoTile>
              <b>Änderungen am Fahrplan:</b>
              <ScheduledExceptions
                setForceOriginalTour={setForceOriginalTour}
                exceptionsForDate={exceptionsForDate}
                onExceptionClick={onExceptionClick}
                activeExceptionId={activeException?.id}
                selectedException={selectedException}
                forceOriginalTour={forceOriginalTour}
                isBlockedByBookingCompletion={isBlockedByBookingCompletion}
              />
            </InfoTile>
          )}
        </TilesLayoutColumn>
      </TilesLayout>

      <ChangeTourDialogOverlay
        open={showChangeDurationDialog}
        schedule={scheduledTour}
        originalSchedule={tour}
        timestamp={timestamp}
        showChangeDurationDialog={showChangeDurationDialog}
        setShowChangeDurationDialog={setShowChangeDurationDialog}
        onUpdate={() => {
          if (props.onUpdate) {
            props.onUpdate(timestamp);
          }
          setChanges(null);
        }}
      />
      <CancelTourDialog
        open={showCancelDialog}
        setOpen={setShowCancelDialog}
        scheduledTour={tour}
        timestamp={timestamp}
        onSubmit={(config, setLoading) => {
          setLoading(true);
          Promise.all([
            // create exception for schedule
            schedulesAPI.createScheduledTourException({
              scheduledTourId: tour.id, // TODO: Use original_tour_id instead of root schedule id
              startDate: getCurrentDayDate(config.startDate || undefined).toISOString(),
              endDate: config.endDate ? getCurrentDayDate(config.endDate).toISOString() : undefined,
              reason: 'cancelled',
              includeTourOnInvoice: config.includeTourOnInvoice || false,
              comment: config.comment || null,
            }),
          ])
            .then(() => {
              setShowCancelDialog(false);
              const startDate = getCurrentDayDate(config.startDate || undefined).toISOString();
              navigate(`/tours/${tour.id}?date=${startDate}`);

              props.onUpdate?.(startDate);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
        }}
      />
    </Wrapper>
  );
}

export default TourDetailsView;
